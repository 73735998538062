@font-face {
font-family: '__Gotham_4e3076';
src: url(/_next/static/media/c030514ac297ccb7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Gotham_4e3076';
src: url(/_next/static/media/41d4edd7d2e76942-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Gotham_4e3076';
src: url(/_next/static/media/00f7d7df36b23917-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__Gotham_Fallback_4e3076';src: local("Arial");ascent-override: 79.82%;descent-override: 19.96%;line-gap-override: 0.00%;size-adjust: 120.26%
}.__className_4e3076 {font-family: '__Gotham_4e3076', '__Gotham_Fallback_4e3076', arial, system-ui
}

@font-face {
font-family: '__FilsonPro_24692a';
src: url(/_next/static/media/e8658bad20781458-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__FilsonPro_24692a';
src: url(/_next/static/media/cf7af1cccee37429-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__FilsonPro_24692a';
src: url(/_next/static/media/930663b4576606b6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__FilsonPro_24692a';
src: url(/_next/static/media/48b0e17981571e4a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__FilsonPro_24692a';
src: url(/_next/static/media/b4a377668af5f426-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__FilsonPro_24692a';
src: url(/_next/static/media/7054c9a8ff4c838e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__FilsonPro_Fallback_24692a';src: local("Arial");ascent-override: 83.11%;descent-override: 23.19%;line-gap-override: 2.26%;size-adjust: 110.34%
}.__className_24692a {font-family: '__FilsonPro_24692a', '__FilsonPro_Fallback_24692a', arial, system-ui
}

